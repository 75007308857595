<template>
    <div class="softList">
<!--        <el-row style="margin-top: 40px;">-->
<!--          <el-col  class="item-col" :span="6" :offset="2">-->
<!--            <div class="item">-->
<!--              <div>-->
<!--                <img src="../assets/logo.png" style="width: 80px;height: 80px">-->
<!--              </div>-->
<!--              <div>-->
<!--                <a href="http://files.51obe.com/vknow_client.msi" target="_blank">Vknow扫描客户端下载</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col class="item-col" :span="6" :offset="2">-->
<!--            <div class="item">-->
<!--              <div>-->
<!--                <img src="../assets/img/koda.jpg" style="width: 80px;height: 80px">-->
<!--              </div>-->
<!--              <div>-->
<!--                <a href="http://files.51obe.com/Koda_i3000_v5.5.exe" target="_blank">柯达i3000扫描仪驱动下载</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col class="item-col" :span="6" :offset="2">-->
<!--            <div class="item">-->
<!--              <div>-->
<!--                <img src="../assets/img/panasoni.jpg" style="width: 80px;height: 80px">-->
<!--              </div>-->
<!--              <div>-->
<!--                <a href="http://files.51obe.com/Panasonic_5058.exe" target="_blank">松下5058扫描仪驱动下载</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col class="item-col" :span="6" :offset="2">-->
<!--            <div class="item">-->
<!--              <div>-->
<!--                <img src="../assets/img/chrome.png" style="width: 80px;height: 80px">-->
<!--              </div>-->
<!--              <div>-->
<!--                <a href="http://files.51obe.com/ChromeSetup.exe" target="_blank">谷歌浏览器下载</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name: 'softList',
        data(){
            return {

            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {


	   },
        methods: {


        },
    }
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss" scoped>
.softList{
  background-color: #F6F7FC;
  .item-col{
    margin: 12px;
    .item{
      background-color: white;
      max-width: 200px;
      padding: 12px;
      border-radius: 10px;
    }
  }
}
</style>

